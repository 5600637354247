<template>
  <NcmList />
</template>

<script>
  export default {
    components: {
      NcmList: () => import('@/components/stock/ncm/NcmList')
    }
  }
</script>